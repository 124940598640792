import type { CombinedError } from 'urql';
interface Args {
  defaultErrorMessage: string;
  errors: {
    hasuraErrorMessage: string | RegExp;
    formatedErrorMessage: string;
  }[];
}
type CustomizeHasuraError = (args: Args) => (error: CombinedError) => string;
const customizeHasuraError: CustomizeHasuraError = args => error => {
  const {
    defaultErrorMessage,
    errors
  } = args;
  return errors.find(e => error.graphQLErrors.some(error => error?.extensions?.code === e.hasuraErrorMessage) || error.message.match(e.hasuraErrorMessage))?.formatedErrorMessage || defaultErrorMessage;
};
export default customizeHasuraError;