import store from '@store';
const useUser = () => {
  const id = store(state => state?.user?.id);
  const setId = store(state => state?.user?.setId);
  const token = store(state => state?.user?.token);
  const setToken = store(state => state?.user?.setToken);
  return ({
    id,
    token,
    setId,
    setToken
  } as const);
};
export default useUser;