import { contentLanguageMap } from '@contexts/I18n';
import store from '@store';
import reverseObject from './reverseObject';
const reverseContentLanguage = reverseObject(contentLanguageMap);
const matchPreferredLanguages = () => {
  const browserLanguages = navigator.languages || [navigator.language];
  const availableLanguages = Object.values(contentLanguageMap);
  for (const language of browserLanguages) {
    for (const availableLanguage of availableLanguages) {
      if (language.startsWith(availableLanguage)) return reverseContentLanguage[availableLanguage];
    }
  }
};
export const setupI18n = async i18n => {
  const fallbackLanguage = async () => {
    const {
      default: dict
    } = (await import('../../../locales/en-US.json').catch(_ => null)) || {};
    if (dict) {
      store.setState(state => {
        state.user.language = 'en';
      });
      return {
        lng: 'en',
        dict
      };
    }
    return false;
  };
  const localeLanguage = async () => {
    if (!navigator.languages) return;

    // match preferred language with available ones
    const localeChoice = matchPreferredLanguages();
    if (!localeChoice) return;
    const {
      default: dict
    } = (await import(`../../../locales/${contentLanguageMap[localeChoice]}.json`).catch(_ => null)) || {};
    if (dict && localeChoice) {
      store.setState(state => {
        state.user.language = localeChoice;
      });
      return {
        lng: localeChoice,
        dict
      };
    }
    return false;
  };
  const customLanguage = async () => {
    const lang = store.getState().user.language;
    const dictFile = contentLanguageMap[(lang as string)];
    const {
      default: dict
    } = (await import(`../../../locales/${dictFile}.json`).catch(_ => null)) || {};
    return dict ? {
      lng: lang,
      dict
    } : false;
  };
  const {
    lng,
    dict
  } = (await customLanguage()) || (await localeLanguage()) || (await fallbackLanguage()) || {};
  i18n.locale(lng, dict);
  return {
    lng,
    dict
  };
};
export default setupI18n;