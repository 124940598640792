import gql from '@gql';
import type {} from 'graphql';
import { useQuery } from 'urql';
export type Role = 'analyst' | 'admin';
const ComplianceUserRoleGQL = gql(`
  query ComplianceUserRoleGQL($user_id: Int!, $team_id: Int!) {
    users(where: { id: { _eq: $user_id } }) {
      roles_users(where: { team_id: { _eq: $team_id } }) {
        id
        team_id
        role {
          permissions(path: "compliance_role")
        }
      }
    }
  }
`);
const usePermissions = (userId, teamId) => {
  const [rolesResult, _reexecuteRowQuery] = useQuery({
    query: ComplianceUserRoleGQL,
    variables: {
      user_id: userId?.toString(),
      team_id: teamId?.toString()
    },
    pause: !userId && !teamId
    // requestPolicy: 'cache-and-network'
  });

  const {
    fetching,
    data,
    error
  } = rolesResult;
  const permissions: Role = data?.users[0].roles_users?.[0]?.role?.permissions;
  return {
    fetchingPermissions: fetching,
    permissions,
    error: error?.message
  };
};
export default usePermissions;